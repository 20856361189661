import React from 'react'
import { usePlace } from 'store/usePlace'

const PoweredBy = () => {
  const { place } = usePlace()

  if (place?.customisation?.bookingBaseUrl) return null

  return (
    <div className='z-100'>
      <span className='text-gray-600 text-[8px]'>
        Powered by{' '}
        <a href='https://2meters.app' className='text-custom-main'>
          2meters
        </a>
      </span>
    </div>
  )
}

export default PoweredBy
